import type { ApolloError } from 'apollo-client';

import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import { LoomAuthTokenQuery } from './graphql/LoomAuthTokenQuery.graphql';
import type { LoomAuthTokenQuery as LoomAuthTokenQueryType } from './graphql/__types__/LoomAuthTokenQuery';
import { LoomAuthStateQuery } from './graphql/LoomAuthStateQuery.graphql';
import { LoomUserStatus } from './graphql/__types__/LoomAuthStateQuery';
import type { LoomAuthStateQuery as LoomAuthStateQueryType } from './graphql/__types__/LoomAuthStateQuery';

type GetAuthTokenResponse = {
	recorderToken?: string;
	tokenError?: ApolloError | Error | null;
};
export const getAuthToken = async (): Promise<GetAuthTokenResponse> => {
	try {
		const tokenQuery = await getApolloClient().query<LoomAuthTokenQueryType>({
			query: LoomAuthTokenQuery,
			fetchPolicy: 'network-only',
		});
		return {
			recorderToken: tokenQuery.data.loomToken?.token,
		};
	} catch (e) {
		getMonitoringClient().submitError(e, {
			attribution: Attribution.CONTENT_TYPES,
		});
		markErrorAsHandled(e);
		return {
			tokenError: e,
		};
	}
};

export const getAuthState = async (): Promise<LoomUserStatus | undefined> => {
	try {
		const authStateQuery = await getApolloClient().query<LoomAuthStateQueryType>({
			query: LoomAuthStateQuery,
		});

		return authStateQuery.data.loomUserStatus;
	} catch (e) {
		getMonitoringClient().submitError(e, {
			attribution: Attribution.CONTENT_TYPES,
		});
		markErrorAsHandled(e);
		return undefined;
	}
};
