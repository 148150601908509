import FeatureGates from '@atlaskit/feature-gate-js-client';

import { AccessStatus, useSessionData } from '@confluence/session-data';
import { isMobileUserAgent, shouldShowMobileWeb } from '@confluence/mobile-detection';

export const useIsLoomBetaEnabled = () => {
	const { accessStatus } = useSessionData();
	const isConfluenceLicensed =
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;

	const isLoomBetaFFEnabled = FeatureGates.checkGate('confluence_loom_beta_enabled');

	// don't enable entry points if user is on a mobile device or viewing mobile web
	const isMobileDeviceOrMobileWeb = isMobileUserAgent() || shouldShowMobileWeb();

	return isLoomBetaFFEnabled && isConfluenceLicensed && !isMobileDeviceOrMobileWeb;
};
