import FeatureGates from '@atlaskit/feature-gate-js-client';

import { isFedRamp } from '@atlassian/atl-context';

import { isMobileUserAgent, shouldShowMobileWeb } from '@confluence/mobile-detection';

import { useIsLoomBetaEnabled } from './useIsLoomBetaEnabled';
import { useTenantHasLoom } from './useTenantHasLoom';
import { useUserHasLoomAccess } from './useUserHasLoomAccess';

export type LoomEntryPointVariant = 'BETA' | 'CO_USE' | 'CROSS_FLOW' | 'CROSS_JOIN' | 'DISABLED';

export const useLoomEntryPointVariant = (): LoomEntryPointVariant => {
	const isBetaEnabled = useIsLoomBetaEnabled();
	const isLoomCrossSellEnabled = FeatureGates.checkGate('confluence_frontend_loom_cross_sell');
	const tenantHasLoom = useTenantHasLoom();
	const userHasLoomAccess = useUserHasLoomAccess();

	if (isFedRamp()) {
		return 'DISABLED';
	}

	// don't enable entry points if user is on a mobile device or viewing mobile web
	if (isMobileUserAgent() || shouldShowMobileWeb()) {
		return 'DISABLED';
	}

	if (isBetaEnabled) {
		return 'BETA';
	}

	if (!isLoomCrossSellEnabled) {
		return 'DISABLED';
	}

	if (!tenantHasLoom) {
		return 'CROSS_FLOW';
	}

	if (!userHasLoomAccess) {
		return 'CROSS_JOIN';
	}

	return 'CO_USE';
};
