import { type ApolloError } from 'apollo-client';
import { getAuthState } from './loomAuthData';

import { LoomUserStatus } from './graphql/__types__/LoomAuthStateQuery';

const graphqlErrorHasMessage = (
	error: ApolloError | Error | null | undefined,
	messageMatch: string,
): boolean => {
	if (error && 'graphQLErrors' in error) {
		return error.graphQLErrors?.some(({ message }) => message.includes(messageMatch));
	}
	return false;
};

const FF_DISABLED_MESSAGE = 'Attempted to fetch Loom token without feature flag enabled';
const UNFOUND_OR_UNMASTERED_USER_MESSAGE = 'Failed to fetch loom auth token. Status code: 404';

export type NoRecordingAccessReason =
	| 'backendFF'
	| 'not-mastered'
	| 'not-mastered-enterprise'
	| 'not-created'
	| 'unexpected';

export const getNoRecordingAccessReason = async (
	tokenError: ApolloError | Error | null | undefined,
): Promise<NoRecordingAccessReason> => {
	if (graphqlErrorHasMessage(tokenError, FF_DISABLED_MESSAGE)) {
		return 'backendFF';
	}

	if (graphqlErrorHasMessage(tokenError, UNFOUND_OR_UNMASTERED_USER_MESSAGE)) {
		const authState = await getAuthState();
		if (authState === LoomUserStatus.NOT_FOUND) {
			return 'not-created';
		}
		if (authState === LoomUserStatus.LINKED) {
			return 'not-mastered';
		}
		if (authState === LoomUserStatus.LINKED_ENTERPRISE) {
			return 'not-mastered-enterprise';
		}
	}

	return 'unexpected';
};
